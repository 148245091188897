@import '../../../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  background-color: var(--marketplaceColor);
  @media (--viewportSmall) {
    padding: 24px 15px;
  }
  @media (--viewportMedium) {
    padding: 60px;
  }
  
}

.semitransparentWrapper {
  margin-top: 20px;
  padding-left: 64px;
  padding-right: 64px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 32px 64px 40px;
  gap: 32px;
  width: 100%;
  background: linear-gradient(
    104.36deg,
    rgba(255, 255, 255, 0.21) 1.09%,
    rgba(0, 0, 0, 0.117) 50.13%,
    rgba(255, 255, 255, 0.21) 99.17%
  );
  border: 1px solid rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(5px);
  border-radius: 20px;

  @media (--viewportMedium) {
    flex-direction: row;
  }

  @media (--viewportMd){
    padding: 32px;  
    gap: 0;
    div{
      width: 100%;
    }
    .title{
      margin-bottom: 10px; 
    }
  }
}

.horizontalWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;

  @media (--viewportMd){
  margin-top: 10px; 
  }
}

.checkbox {
  width: 18px;
  height: 18px;
}

.title {
  font-family: 'Montserrat';
  font-size: 26px;
  font-weight: 700;
  padding: 0;
  margin: 0;
  line-height: 18px;
  color: white;

  @media (--viewportMedium) {
    line-height: 37px; font-size: 30px;
  } 
  @media (--viewportMd) {
    span{
      display: block;
      line-height: normal; 
    }
  }
}

.subtitle {
  padding: 0;
  margin: 0;
  margin-top: 21px;
  font-style: normal;
  /* font-weight: 600; */
  font-size: 20px;
  line-height: 27px;
  color: var(--marketplaceSecondaryColor);

  @media (--viewportMedium) {
    line-height: 27px;
    font-size: 20px;
  }
  @media (--viewportSmall) {
    margin-top: 0;
    margin-bottom: 10px;
  }
  @media (--viewportSm){
    margin-top: 0;
  }
}

.benefit {
  font-style: normal;
  font-size: 20px;
  line-height: 27px;
  color: #fefefe;
  @media (--viewportSm){ 
    font-size: 16px;
    margin: 2px auto;
    width: 100%;
  }
}

.info {
  padding: 0;
  margin-top: 30px;
  margin-bottom: 40px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: white;
  @media (--viewportMd){
    line-height: 1.6;
    margin-bottom: 0;
  }
}

.infoHighlight {
  composes: info;
  color: var(--marketplaceSecondaryColor);
}

.marginTop {
  margin-top: 16px;
}

.signUp {
  text-decoration: none !important;
}
