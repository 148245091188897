@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  background-color: var(--marketplaceColor);
}

.rootBackground {
  background-image: url(./images/background-top.png);
  background-repeat: no-repeat;
  background-position: 0 top;
  background-size: 100%;
  
}

.horizontalWrapper {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  gap: 40px;
  padding-bottom: 20px;
  margin-left: 64px;
  margin-right: 64px;
  @media (--viewportLg){   
    margin: 0 auto;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
  }
}

.headingContainer {
  display: flex;
  flex-direction: row;
  padding: 30px 64px;
  align-items: center;
  @media (--viewportSm){
    padding: 25px
  }
}

.heading {
  margin-left: 16px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 49px;
  color: #ffffff;

  @media (--viewportLg){
    font-size: 30px;
  }
}

.headingIcon {
  /* width: 30px; */
  height: 30px;
}

.roundedContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 40px 63px;
  gap: 10px;
  background: #ffffff;
  border: 1px solid rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(5px);
  border-radius: 20px;
  width: 100%;
  margin-top: 0px !important;
  min-height: 500px;
  margin-right: 64px;
  @media (--viewportLg){
    flex-wrap: wrap;
    margin: auto;
  }
  @media (--viewportMd){
    padding: 20px; 
    min-height: auto;
  }
}

.step {
  color: var(--marketplaceColor);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.64px;
  max-width: 152px;
  text-align: center;
  margin-top: 14px;
}

.statusLabel {
  color: var(--marketplaceColor);
  font-family: 'Open Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
  padding: 0;
}

.status {
  color: var(--marketplaceColor);
  font-family: 'Open Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  padding: 0;
}

.buttonsWrapper {
  text-align: end;
}

.stepsWrapper {
  display: flex;
  flex-direction: row;
  gap: 19px;
  align-items: center;
  justify-content: space-between;
}

.circlesWrapper .circle:first-child {
  margin-left: 50px;
}

.circlesWrapper .circle:last-child {
  margin-right: 50px;
}

.circlesWrapper {
  display: flex;
  flex-direction: row;
  gap: 19px;
  align-items: center;
  justify-content: space-between;
}

.circle {
  width: 45px;
  height: 45px;
  flex-shrink: 0;
  background-color: var(--marketplaceColor);
  border-radius: 45px;
  display: flex;
  align-items: center;
  justify-content: center;

  color: #fff;
  font-family: 'Open Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.progressActive {
  position: relative;
  height: 9px;
  border-radius: 5px;
  width: 100%;
  background-color: #b7bcbf;
}

.progressDone {
  position: relative;
  height: 9px;
  border-radius: 5px;
  width: 100%;
  background-color: var(--marketplaceColor);
}

.progressActive:before {
  content: '';
  position: absolute;
  top: 0;
  right: 50%;
  bottom: 0;
  left: 0;
  height: 9px;
  border-radius: 5px;
  background-color: var(--marketplaceColor);
}

.progress {
  height: 9px;
  border-radius: 5px;
  width: 100%;
  background-color: #b7bcbf;
}

.title {
  color: var(--marketplaceColor);
  text-align: center;
  align-self: stretch;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 31px;
  letter-spacing: 0.8px;
}

.separator {
  height: 1px;
  width: 100%;
  margin-top: 24px;
  margin-bottom: 24px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%), #013957;
}

.buttonWrapper {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

.button {
  display: flex;
  width: 320px;
  height: 40px;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  gap: 8px;
  @media (--viewportSm){
    width: 100%;
  }
}

.info {
  color: #000;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.56px;
}

.mainImage {
  height: 152px;
  width: 144px;
}

.paddedContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 25%;
  padding-right: 25%;
  padding-top: 40px;

  @media (--viewportMd){
    padding: 0;
    
    width: 100%;
  }
}