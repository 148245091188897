@import '../../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px 40px;
  gap: 8px;
  max-width: 344px;
  background: linear-gradient(104.36deg, rgba(255, 255, 255, 0.21) 1.09%, rgba(0, 0, 0, 0.117) 50.13%, rgba(255, 255, 255, 0.21) 99.17%);
  border: 1px solid rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(5px);
  border-radius: 20px;


}

.horizontalWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
}

.sectionWrapper {
  display: flex;
  flex-direction: column;
  @media (--viewportLg){
    gap: 5px;
    width: 100%;
  }
}

.sectionInfo {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #FFFFFF;
  margin: 0px;
  
}

.sectionInfoComplete {
  composes: sectionInfo;
  color: var(--marketplaceSecondaryColor);
}

.arrow {
  display: none;
}

.arrowCurrent {
  width: 6px;
  height: 14px;
  display: block;
}

.sectionInfoCurrent {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: var(--marketplaceSecondaryColor);
  margin: 0px;
}

.title {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  color: #FFFFFF;
  @media (--viewportSm){
    margin: 0 auto; 
  } 
}

.info {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 31px;
  letter-spacing: 0.04em;
  color: #FFFFFF;
  margin: 0px;
}

.icon {
  width: 14px;
  height: 14px;
}

.completeIcon {
  width: 14px;
  height: 14px;
  margin-right: 4px;
}