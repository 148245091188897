@import '../../../../styles/customMediaQueries.css';

.root {
    display: flex;
    background-image: url('./images/background.png');
    background-size: cover;
    background-position: center;
    align-items: center;

    @media (--viewportMedium) {
        min-height: 754px;
    }
}


.semitransparentWrapper {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 715px;
    padding: 32px 64px 40px;
    gap: 8px;
    background: linear-gradient(104.36deg, rgba(255, 255, 255, 0.35) 1.09%, rgba(0, 0, 0, 0.195) 50.13%, rgba(255, 255, 255, 0.35) 99.17%);
    border: 1px solid rgba(255, 255, 255, 0.05);
    box-shadow: 0px 0px 0px 4px rgba(255, 255, 255, 0.01);
    border-radius: 20px;  
 
    @media (--viewportMedium) {
        margin-left: 10%;
    } 
     @media (--viewportMd) {
        margin-left: auto;
        padding: 30px;
        margin-right: auto;
        max-width: calc(100% - 30px);
    } 
}

.title {
    font-family: "Montserrat";
    font-size: 28px;  
    font-weight: 700;
     line-height: normal;  
    padding: 0;
    margin: 0; 
    color: var(--marketplaceColor); 
    @media (--viewportMedium) {
        line-height: normal;
        font-size: 40px;
      } 
}

.subtitle {
    padding: 0;
    margin: 0;
     font-size: 28px;  
    font-weight: 700;
     line-height: 28px;  
    letter-spacing: 1.3px;
    display: contents;
    color: var(--colorWhite); 
    @media (--viewportMedium) {
        line-height: 37px;
        font-size: 40px;
      }
}

.info {
    padding: 0;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 18px;
    line-height: 25px;
    color: white;
    font-weight: 400;
    @media (--viewportSm){
        font-size: 16px;
    }
}

.button {
    width: 100%;

    &:hover {
        text-decoration: none;
    }
}