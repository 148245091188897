@import '../../../styles/customMediaQueries.css';

.root {
  position: relative;
  box-shadow: var(--boxShadowBottomForm);

  @media (--viewportLarge) {
    box-shadow: none;

    /* Clearfix */
    width: 100%;
    overflow: hidden;
  }
}

.spinner {
  stroke: var(--colorWhite);
  width: 18px;
  height: 18px;
  stroke-width: 4px;
}

.fillSuccess {
  fill: var(--colorSuccess);
}

.strokeMatter {
  stroke: var(--colorGrey700);
}

.submitContainer {
  display: flex;
  flex-direction: row;
}

.errorContainer {
  display: block;
  flex: 1;
  text-align: right;
  padding: 26px 24px 0 0;
}

.error {
  composes: h5 from global;

  font-weight: var(--fontWeightMedium);
  color: var(--colorFail);
  margin: 0;
}

.submitButton {
  background-color: transparent;
  border: none;
  border-radius: 30px;
  margin-top: 1px;
  margin-right: 4px;
  padding: 10px;

  &:hover {
    background-color: rgb(0 123 255 / 25%);
  }
}

.sendIcon {
  margin: -3px 5px 0 0;
}

.textInput {
  background-color: transparent;
  color: white;
  border: none;
  width: 100%;
  outline: none;
  box-shadow: 0 0 0 0rem rgb(0 123 255 / 25%);
  flex-grow: 1;
  /* margin-left: 10px; */
  margin-right: 20px;

  &:hover {
    border: none;
  }

  &:focus {
    background-color: transparent;
    box-shadow: 0 0 0 0rem red;
  }
}

.textInputRoot {
  display: flex;
  width: 100%;
}

.sendMessageContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 0px, 16px, 0px, 24px;
  border-radius: 20px;
  border: 1px solid white;

}