@import '../../../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: var(--marketplaceColor);
  background-image: url('./images/background-bottom-full.png');
  background-size: 100%;
  background-position: bottom;
  align-items: flex-start;

  @media (--viewportMedium) {
    padding: 60px;
  }
}

.panelWrapper {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-items: center;
  justify-items: center;
  padding: 32px 64px 40px;
  gap: 8px;
  height: 100%;
  background: linear-gradient(
    104.36deg,
    rgba(255, 255, 255, 0.21) 1.09%,
    rgba(0, 0, 0, 0.117) 50.13%,
    rgba(255, 255, 255, 0.21) 99.17%
  );
  border: 1px solid rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(5px);
  border-radius: 20px;
  width: 100%;
  @media (--viewportLg) {
    padding: 32px 15px;
    gap: 15px;
  }
  
}

.line {
  width: 214px;
  height: 4px;
  background: linear-gradient(
    90deg,
    rgba(242, 199, 92, 0.2) 0%,
    #f2c75c 49.3%,
    rgba(242, 199, 92, 0.2) 98.6%
  );
  transform: matrix(-1, 0, 0, 1, 0, 0);
  @media (--viewportLg) {
   margin-bottom: 20px;
  }
}

.horizontalWrapper {
  display: flex;
  /* flex-direction: column; */
  flex-direction: row;
  width: 100%;
  gap: 24px;
  align-items: center;
  justify-items: center;
  justify-content: space-around; 

  @media (--viewportMd){
    gap: 12px;
  }
}
.subtitleWrapper{
  p{
    text-align: left;
  } 
}
 

.desktop{
  @media (--viewportSm){
    display: none;

  }
}

.mobile{
  @media (--viewportSmall){
  display: none;
  }
}
.mobileTab{
  width: 100%;
}
.mobileWf{
  display: none;
  @media (--viewportSm){
    display: flex;
    justify-content: space-around;
    margin-bottom: 32px;
    &>p{
      padding: 10px 20px;
      flex: 0 0 auto;
      transition: 0.3s ease all;
      border: 2px solid transparent;
      border-radius: 30px;
      font-size: 16px;
      &.active{
        border: 2px solid var(--colorWhite)
      }
    } 
  } 
}
.verticalWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 6px;
  margin: 34px;
  @media (--viewportLg) {
    gap: 15px;
    margin: auto;
    &:last-child{
      margin: 30px 0 0;
    }
  }
}

.icon {
  width: 20px;
  height: 20px;
}

.title {
  font-family: 'Montserrat'; 
  font-weight: 700;
  font-size: 26px;
  padding: 0;
  margin-bottom: 32px;
  line-height: normal;
  color: var(--colorWhite);

  @media (--viewportMedium) {
    line-height: 37px;
    font-size: 30px;
  }
}

.subtitle {
  padding: 0;
  margin: 0;
  font-style: normal;
  /* font-weight: 600; */
  font-size: 20px;
  line-height: 27px;
  color: white;
  text-align: center;

  @media (--viewportMedium) {
    line-height: 27px;
    font-size: 20px; 
  } 
}
