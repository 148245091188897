@import '../../styles/customMediaQueries.css';

.root {
    background-color: var(--marketplaceColor);
    background-image: url(./images/background.png);
    background-position: top;
    background-repeat: repeat-x;
    display: flex;
    justify-content: flex-end;
}

.horizontalWrapper {
    align-items: start;
    display: flex;
    flex-direction: row;
    gap: 10px;
    padding-bottom: 20px;
    height: 100%;
    width: 100%;
}


.message,
.ownMessage {
    /* Preserve newlines, but collapse other whitespace */
    white-space: pre-line;
    max-width: 433px;
}

.message {
    display: flex;
    flex-direction: row;
}

.ownMessage {
    float: right;
    display: flex;
    flex-direction: row;
    align-items: start;
    gap: 12px;
}

.avatar {
    flex-shrink: 0;
    margin: 0px 12px 0 0;

    @media (--viewportMedium) {
        margin: 0px 12px 0 0;
    }
}

.ownMessageContentWrapper {
    composes: clearfix from global;
}

.ownMessageContent {
    composes: textSmall from global;

    display: inline-block;
    margin: 0;
    box-shadow: var(--boxShadow);

    padding: 8px 40px 8px 40px;
    border-radius: 10px 0px 10px 10px;
    gap: 32px;
    background-color: white;
}

.messageContent {
    composes: textSmall from global;

    display: inline-block;
    margin: 0;
    box-shadow: var(--boxShadow);

    padding: 8px 40px 8px 40px;
    border-radius: 0px 10px 10px 10px;
    gap: 32px;
    background-color: white;
}



.messageItem {
    margin-bottom: 20px;
    margin-right: 20px;
    /* Clearfix */
    composes: clearfix from global;

    @media (--viewportMedium) {
        margin-bottom: 17px;
    }

    &:last-of-type {
        margin-bottom: 0;
    }
}

.messageContent {
    flex: 1;
}

.ownMessageContent {
    background-color: white;
    color: var(--marketplaceColor);
    float: right;
}

.messageDate,
.ownMessageDate {
    composes: marketplaceMessageDateFontStyles from global;

    margin: 11px 0 0 0;
    color: var(--colorGrey300);

    @media (--viewportMedium) {
        margin: 0 0 0 0;
    }
}

.ownMessageDate {
    text-align: right;

    @media (--viewportMedium) {
        margin: 0;
    }
}

.mainContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 32px 40px;
    gap: 8px;
    height: 600px;
    margin-left: 60px;
    margin-right: 60px;
    background: linear-gradient(104.36deg, rgba(255, 255, 255, 0.21) 1.09%, rgba(0, 0, 0, 0.117) 50.13%, rgba(255, 255, 255, 0.21) 99.17%);
    border: 1px solid rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(5px);
    border-radius: 20px;
}

.leftContainer {
    min-width: 250px;
    padding-left: 20px;
    padding-right: 20px;
    height: 100%;
    overflow: hidden;
}

.horizontalLine {
    height: 1px;
    background-color: white;
    width: 100%;
}

.verticalLine {
    height: 100%;
    background-color: white;
    width: 1px;
}

.title {
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: white;
}

.headingContainer {
    display: flex;
    flex-direction: row;
    padding: 30px;
    align-items: center;
}

.heading {
    margin-left: 16px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 49px;
    color: #FFFFFF;
}

.headingIcon {
    /* width: 30px; */
    height: 30px;
}

@import '../../../../styles/customMediaQueries.css';


.roundedContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 40px 63px;
    gap: 10px;
    background: #FFFFFF;
    border: 1px solid rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(5px);
    border-radius: 20px;
    width: 100%;
    margin-top: 0px !important;
    min-height: 500px;
    margin-left: 40px;
    margin-right: 40px;
}

.step {
    color: var(--marketplaceColor);
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.64px;
    max-width: 152px;
    text-align: center;
    margin-top: 14px;
}

.statusLabel {
    color: var(--marketplaceColor);
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0;
    padding: 0;
}

.status {
    color: var(--marketplaceColor);
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
    padding: 0;
}

.buttonsWrapper {
    text-align: end;
}

.stepsWrapper {
    display: flex;
    flex-direction: row;
    gap: 19px;
    align-items: center;
    justify-content: space-between;
}

.circlesWrapper .circle:first-child {
    margin-left: 50px;
}

.circlesWrapper .circle:last-child {
    margin-right: 50px;
}

.circlesWrapper {
    display: flex;
    flex-direction: row;
    gap: 19px;
    align-items: center;
    justify-content: space-between;
}

.circle {
    width: 45px;
    height: 45px;
    flex-shrink: 0;
    background-color: var(--marketplaceColor);
    border-radius: 45px;
    display: flex;
    align-items: center;
    justify-content: center;

    color: #FFF;
    font-family: "Open Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.progressActive {
    position: relative;
    height: 9px;
    border-radius: 5px;
    width: 100%;
    background-color: #B7BCBF;
}

.progressActive:before {
    content: "";
    position: absolute;
    top: 0;
    right: 50%;
    bottom: 0;
    left: 0;
    height: 9px;
    border-radius: 5px;
    background-color: var(--marketplaceColor);
}

.progress {
    height: 9px;
    border-radius: 5px;
    width: 100%;
    background-color: #B7BCBF;
}

/* .title {
    color: var(--marketplaceColor);
    text-align: center;
    align-self: stretch;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 31px;
    letter-spacing: 0.8px;
} */

.separator {
    height: 1px;
    width: 100%;
    margin-top: 24px;
    margin-bottom: 24px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), #013957;
}

.buttonWrapper {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
}

.button {
    display: flex;
    width: 320px;
    height: 40px;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.info {
    color: #000;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0.56px;
}

.lightText {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.04em;
    color: white;
    text-align: left;
}

.lightSmallText {
    font-family: Open Sans;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: white;
}

.topbar {
    z-index: 1;
    /* ensure that Topbar shadow overlays content background */
}

.mobileTopbar {
    /* Size */
    width: 100%;
    height: var(--topbarHeight);

    /* Layout for child components */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    /* fill */
    background-color: var(--colorWhite);

    /* shadows */
    box-shadow: none;

    @media (--viewportMedium) {
        display: none;
    }
}

.desktopTopbar,
.mobileTopbar {
    box-shadow: none;

    @media (--viewportLarge) {
        box-shadow: var(--boxShadowLight);
    }
}

.navigation {
    /* Layout */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 29px 24px 0 24px;

    border-top-width: 1px;
    border-top-color: var(--colorGrey100);
    border-top-style: solid;
    background-color: var(--colorWhite);
    box-shadow: var(--boxShadow);

    @media (--viewportMedium) {
        padding: 24px 24px 0 24px;
    }

    @media (--viewportLarge) {
        flex-wrap: unset;
        padding: 40px 0 82px 36px;
        flex-direction: column;
        justify-content: flex-start;
        border: none;
        box-shadow: none;
        background-color: transparent;
    }

    @media (--viewportLargeWithPaddings) {
        padding: 40px 0 82px calc((100% - 1056px) / 2);
    }
}


.tabs {
    padding-top: 2px;

    display: flex;
    flex-direction: row;
    overflow-x: auto;

    @media (--viewportMedium) {
        padding-top: 12px;
        padding-top: 20px;
    }

    @media (--viewportLarge) {
        padding-top: 24px;
        flex-direction: column;
    }
}

.tab {
    margin-left: 16px;

    &:first-child {
        margin-left: 0;
    }

    @media (--viewportLarge) {
        margin-left: 0;
    }
}

.error {
    color: var(--colorFail);
    margin: 23px 24px 24px 24px;

    @media (--viewportLarge) {
        margin-top: 18px;
    }
}

.noResults {
    margin-left: 24px;
    margin-right: 24px;
    margin-top: 1px;
    color: white;

    @media (--viewportLarge) {
        margin-top: 25px;
    }
}

.itemList {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
}

.messagesList {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    /* position: fixed; */
}

@keyframes loadingSpinnerFadeIn {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.listItemsLoading {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    width: 100%;
    opacity: 0;

    /* animation shorthand property doesn't work with local scope of CSS Modules */
    animation-duration: 1s;
    animation-name: loadingSpinnerFadeIn;
    animation-fill-mode: forwards;
}

.listItem {
    /* Layout */
    margin-bottom: 10px;
    padding-bottom: 12px;

    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: var(--colorGrey100);

    &:last-child {
        border-bottom: none;
    }

    @media (--viewportLarge) {
        margin-bottom: 16px;
    }
}

.item {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.itemAvatar {
    display: flex;
    align-items: center;
    margin-right: 8px;

    @media (--viewportMedium) {
        display: block;
        align-items: unset;
    }

    @media (--viewportLarge) {
        margin-top: 3px;
        margin-right: 7px;
    }
}


.itemAvatarSelected {
    composes: itemAvatar;
    border: solid 3px #EF8943;
    border-radius: 50%;
}

.itemLink {
    flex: 1;

    /* Layout */
    display: inline-grid;
    grid-template-columns: 22px auto;
    grid-template-areas:
        'dot name    state'
        '... item    state'
        '... details state';
    padding: 2px 0 6px;
    gap: 4px 0;

    /* Remove text underline from this link */
    text-decoration: none;

    &:hover {
        text-decoration: none;
    }

    &:hover .itemUsername {
        /* Hightlight the username when the whole item is hovered */
        color: var(--marketplaceColor);
    }
}

.rowNotificationDot {
    grid-area: dot;
    justify-self: center;
    align-self: flex-start;
    margin-top: 8px;

    width: 6px;
    height: 6px;

    @media (--viewportLarge) {
        margin-top: 5px;
        margin-left: 5px;
    }

    @media (--viewportLarge) {
        justify-self: flex-start;
        width: 8px;
        height: 8px;
    }
}

.notificationDot {
    color: var(--colorWhite);

    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: var(--colorFail);
}

.itemUsername {
    grid-area: name;

    /* Font */
    composes: textSmall from global;
    font-weight: var(--fontWeightBold);
    color: var(--colorGrey700);

    margin-top: 0;
    margin-bottom: 0;

    @media (--viewportMedium) {
        line-height: 16px;
        padding: 0;
        margin-top: 0;
        margin-bottom: 0;
    }
}

.itemTitle {
    composes: marketplaceTinyFontStyles from global;
    grid-area: item;
    color: var(--colorGrey700);

    margin-top: 0;
    margin-bottom: 0;
}

.itemDetails {
    composes: marketplaceTinyFontStyles from global;
    grid-area: details;
    color: var(--colorGrey700);

    margin-top: 0;
    margin-bottom: 0;
}

.itemState {
    grid-area: state;

    /* Font */
    composes: marketplaceTinyFontStyles from global;
    line-height: 18px;
    font-weight: var(--fontWeightMedium);
    letter-spacing: normal;

    justify-self: flex-end;
    text-align: end;

    margin-top: 6px;
    margin-bottom: 0px;

    @media (--viewportMedium) {
        margin-top: 0;
        margin-bottom: 0;
    }
}

.pagination {
    margin: auto 24px 0 24px;
}

/* Transaction status affects to certain font colors and weights */

.stateName {
    /* This class is empty on purpose, it is used below for banned users */
}

.stateActionNeeded {
    font-weight: var(--fontWeightMedium);
    color: var(--colorAttention);
}

.stateNoActionNeeded {
    color: var(--colorGrey700);
}

.stateConcluded {
    color: var(--colorGrey300);
}

.bookingActionNeeded {
    color: var(--colorGrey700);
    margin-top: 4px;

    @media (--viewportMedium) {
        margin-top: 9px;
    }
}

.bookingNoActionNeeded {
    color: var(--colorGrey300);
}

.lastTransitionedAtEmphasized {
    color: var(--colorGrey700);
    font-weight: var(--fontWeightMedium);
}

.lastTransitionedAtNotEmphasized {
    color: var(--colorGrey300);
}

.bannedUserLink {

    & .itemUsername,
    &:hover .itemUsername,
    & .stateName {
        color: var(--colorGrey300);
    }
}

.sendMessageForm {
    position: relative;
    margin: 46px 24px 0 24px;

    @media (--viewportMedium) {
        margin: 49px 24px 0 24px;
        border-top: 0;
    }

    @media (--viewportLarge) {
        margin: 48px 0 0 0;
    }
}

.messagesWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin-left: 30px;
    justify-content: space-between;
}