@import '../../../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 60px;
  background-color: white;
  @media (--viewportSmall) {
    padding: 32px;
  }
  @media (--viewportMedium) {
    gap: 40px;
  }
  @media (--viewportLarge) {
    padding: 44px 15px;
  }
 @media (--viewportSm){
  margin: 0 15px;
  padding: 32px;
 }
}

.horizontalWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  @media (--viewportMedium) {
    flex-direction: row;
  }
  @media (--viewportLg) {
    flex-wrap: wrap;
    gap: 40px;
  } 

}

.verticalWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 40px;
  width: 100%;
  @media (--viewportLg) {
    max-width: 40vw;
  }
  @media (--viewportSm) {
    max-width: 100%;
    padding: 0;
  }
}
.horizontalReverse{
  @media (--viewportSm) {
    flex-direction: column-reverse; 
    margin-bottom: 30px;
  }
}
.image {
  width: 130%;
  @media (--viewportLg) {
    max-width: 57vw;
  }
  @media (--viewportSm) {
    max-width: 100%;
    min-height: 452px;
    object-fit: cover;
  }
}

.title {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 27px;
  
  color: var(--marketplaceSecondaryColor);

  @media (--viewportMedium) {
    line-height: 37px; 
  }
  @media (--viewportMd) {
  margin-bottom: 0;
  } 
  @media (--viewportSm) {
    font-size: 24px;
  }
}

.info {
  padding: 0;
  margin-top: 30px;
  margin-bottom: 40px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: var(--marketplaceColor);
  @media (--viewportSm) {
    margin-bottom: 30px;
    span{ 
      line-height: 1.5;
    }
  }
}

.infoHighlight {
  composes: info;
  color: var(--marketplaceSecondaryColor);
}
