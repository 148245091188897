@import '../../../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: var(--marketplaceColor);
  background-image: url('./images/background.png');
  background-size: cover;
  background-position: center;
  align-items: center;

  @media (--viewportMedium) {
    min-height: 418px;
  }
}

.semitransparentWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px 64px 40px;
  margin: 20px;
  background: linear-gradient(
    104.36deg,
    rgba(255, 255, 255, 0.21) 1.09%,
    rgba(0, 0, 0, 0.117) 50.13%,
    rgba(255, 255, 255, 0.21) 99.17%
  );
  border: 1px solid rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(5px);
  border-radius: 20px;
  gap: 48px;

  @media (--viewportMedium) {
    margin: 0 147px;
  }
  @media (--viewportMd){
    padding: 32px 36px 40px;
    margin: 42px 15px;
  }
}

.title {
  font-family: 'Montserrat';
  font-size: 16px;
  font-weight: 700;
  padding: 0;
  margin: 0;
  line-height: 18px;
  color: white;
  text-align: center;

  @media (--viewportMedium) {
    line-height: 37px;
    font-size: 30px;
  }
  @media (--viewportMd){
    line-height: normal;
    font-size: 30px;
    letter-spacing: 4px;
  }
  @media (--viewportSm){
  line-height: normal;
  font-size: 24px;
  letter-spacing: normal;
  }
}

.button {
  composes: buttonHighlighted from global;
  max-width: 500px;
}

.signUp {
  text-decoration: none !important;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: var(--colorWhite);
}
