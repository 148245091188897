@import '../../../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  background-color: var(--marketplaceColor);

  @media (--viewportMedium) {
    padding: 60px;
  }
}

.testimonialWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 22px 34px 20px;
  gap: 12px;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;

  @media (--viewportMedium) {
    padding: 32px 64px 40px;
    gap: 32px;
  }
}

.horizontalWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;
  justify-content: space-between;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.quote {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 37px;
  letter-spacing: 0.04em;
  text-align: start;
  width: 100%;
  color: var(--marketplaceSecondaryColor);
}

.title {
  font-family: 'Montserrat';
  font-size: 16px;
  font-weight: 700;
  padding: 0;
  margin-bottom: 32px;
  line-height: 18px;
  color: white;

  @media (--viewportMedium) {
    line-height: 37px;
    font-size: 30px;
  }
}

.text {
  padding: 0;
  margin: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}

.author {
  padding: 0;
  margin: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: var(--marketplaceSecondaryColor);
}

.date {
  padding: 0;
  margin: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  letter-spacing: 0.04em;
  color: var(--marketplaceColor);
}
